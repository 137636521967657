<i18n lang="json">{
    "en-BE": {
        "refinancing": "Refinancing or reinstatement",
        "disclaimer": {
            "avm": " "
        }
    },
    "fr-BE": {
        "refinancing": "Refinancement ou remise en vigueur",
        "disclaimer": {
            "avm": " "
        }
    },
    "nl-BE": {
        "refinancing": "Herfinanciering of wederopname",
        "disclaimer": {
            "avm": " "
        }
    }
}</i18n>

<template>
    <div class="report-container">
        <div id="toc_val_report" class="card">
            <div class="card-content content is-small has-text-centered">
                <h4>{{ $t('Contents') }}</h4>
                <div class="has-text-left">
                    <ul class="mb-1">
                        <li>
                            <a href="#page-cover">{{ $t('front.title') }}</a>
                        </li>
                    </ul>
                    <ol class="1 mt-1">
                        <li v-for="page in Object.values(pages)" :key="page.name">
                            <a :href="`#page-${page.name}`">
                                {{
                                    page.sections[0].data?.name ??
                                        $t(`section.${page.sections[0].name}`)
                                }}
                            </a>
                        </li>
                    </ol>
                    <b-field>
                        <b-checkbox v-model="showNull" size="small">
                            Show missing
                        </b-checkbox>
                    </b-field>
                </div>
            </div>
        </div>
        <div id="report-vue" class="has-text-centered is-size-7">
            <div id="page-cover" class="page cover-page is-flex is-flex-direction-column">
                <div class="content section">
                    <div class="columns is-multiline">
                        <div class="column is-12 is-centered mt-10">
                            <img
                                width="300px"
                                src="@/shared/assets/icons/rockestate-logo-square-dark.svg"
                            />
                        </div>
                        <div
                            class="column is-12 mt-10 has-text-centered is-flex is-flex-direction-column"
                        >
                            <div class="is-inline is-size-4">
                                {{ $t('front.title') }}
                            </div>
                            <div
                                class="is-inline is-size-4 has-text-weight-bold has-text-primary"
                            >
                                {{ formatted_date }}
                            </div>
                        </div>
                        <div class="column is-12">
                            <p class="title has-text-weight-bold">
                                {{ getAddress.full_address }}
                            </p>
                        </div>
                        <div v-if="getAddress.via_address !== ''" class="column is-12">
                            <p class="has-text-centered has-text-black">
                                (via {{ getAddress.via_address }})
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.performed_for') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ performedFor }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.valuer') }}</p>
                            <template v-if="isOVM && ovm_valuer">
                                <p class="has-text-weight-bold has-text-black">
                                    {{ displayUser(ovm_valuer) }}
                                </p>
                                <template v-if="accreditations.length > 0">
                                    <p
                                        v-for="acc in accreditations"
                                        :key="acc.institution"
                                    >
                                        {{ acc.institution }}:
                                        {{ acc.accreditation_number }}
                                    </p>
                                </template>
                            </template>
                            <template v-else>
                                <p class="has-text-weight-bold has-text-black">
                                    {{ dvm_valuer_name }}
                                </p>
                            </template>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.monitor') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{
                                    getValuationType === 'dvm' ? 'Pietjan Vandooren' : '-'
                                }}
                            </p>
                        </div>

                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('request.purpose') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ $t('value.' + purpose ?? 'new_loan') }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.type') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ $t(`value.${getValuationType}`) }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p>{{ $t('request.references') }}</p>
                            <p>
                                <span>Rock.estate:</span>
                                <span class="has-text-weight-bold has-text-black">
                                    {{ getFullValuationStore.valuation_request_ref }}
                                </span>
                            </p>
                            <p>
                                <span>{{ $t('request.bank') }}:</span>
                                <span class="has-text-weight-bold has-text-black">
                                    {{ getFullValuationStore.customer_ref }}
                                </span>
                            </p>
                        </div>

                        <div class="column is-12 mt-6">
                            <p class="is-7">
                                {{ $t(`disclaimer.${getValuationType}`) }}
                            </p>
                        </div>
                    </div>
                </div>
                <report-footer></report-footer>
                <!-- TODO: make conditional to environment being dev/test: -->
                <!-- <div v-if="reportWatermark" class="cover_disclaimer">
                    TEST REPORT
                </div> -->
            </div>
            <report-page id="page-general" :page="pages.general">
                <template #sidebar>
                    <div v-if="!isPlot" class="image is-square mb-2">
                        <GmapStreetViewPanorama
                            v-if="getView.gsv_available"
                            style="width: 100%; height: 100%"
                            class="pano has-ratio"
                            :pov="{
                                heading: getView.gsv_ph,
                                pitch: getView.gsv_pp,
                            }"
                            :zoom="getView.gsv_pz"
                            :pano="getView.gsv_p"
                            :options="{
                                disableDefaultUI: true,
                                fullscreenControl: false,
                                addressControl: false,
                                scrollwheel: false,
                                panningGesturesEnabled: $route.query.static === 'true',
                                clickToGo: false,
                            }"
                        ></GmapStreetViewPanorama>
                        <div v-else class="card has-ratio">
                            <div class="card-content">
                                <div class="content">
                                    {{ $t('streetview_unavailable') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="image is-square my-2">
                        <re-map
                            :building-id="getFeatures.building_id"
                            :parcel-ids="getFeatures.parcel_ids"
                            :level="getFeatures.level"
                            :center="{
                                lat: getFeatures.f_lat,
                                lng: getFeatures.f_lng,
                            }"
                            :map-scene2d="get_map_scene2d"
                            :read-only="true"
                            :focus-layer="'building'"
                            class="has-ratio"
                            :size="'small'"
                            :layers-control-visible="false"
                            :flood-visible="false"
                            :options="map_options"
                            :region="getFeatures.region"
                        >
                            <template slot="custom-layer">
                                <rotating-marker
                                    v-if="getView.gsv_available"
                                    :lat-lng="[getView.gsv_lat, getView.gsv_lng]"
                                    :heading="getView.gsv_ph"
                                    :scale-y="getView.gsv_pz"
                                    :icon="icon"
                                ></rotating-marker>
                            </template>
                        </re-map>
                    </div>
                    <div class="image is-square my-2">
                        <re-map
                            :building-id="getFeatures.building_id"
                            :parcel-ids="getFeatures.parcel_ids"
                            :level="getFeatures.level"
                            :region="getFeatures.region"
                            :map-scene2d="get_map_scene2d"
                            :center="{
                                lat: getFeatures.f_lat,
                                lng: getFeatures.f_lng,
                            }"
                            :read-only="true"
                            :focus-layer="'building'"
                            class="has-ratio"
                            :size="'small'"
                            :layers-control-visible="false"
                            :options="map_options"
                            :aerial-tile-visible="false"
                            :cadastral-tile-visible="false"
                        ></re-map>
                    </div>
                    <div v-if="!isPlot" class="image is-3by2 mt-2">
                        <renderer
                            v-if="getFullValuationStore.Address.features.level === '3'"
                            class="has-ratio"
                            :building-id="
                                getFullValuationStore.Address.features.building_id
                            "
                            :map-scene3d="get_map_scene3d"
                            :show-attributions="false"
                            :auto-rotate="$route.query.static !== 'true'"
                        ></renderer>
                        <div v-else class="card has-ratio">
                            <div class="card-content">
                                <div class="content">
                                    {{ $t('3d model unavailable') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #preSectionsContent>
                    <article class="message is-dark">
                        <div class="message-body">
                            <report-market-value
                                header="valuation.market_value"
                                :value="getValuation.market_value"
                            />
                            <template v-if="packageType === 'renovation_light'">
                                <report-market-value
                                    header="valuation.market_value_post_renovation"
                                    :value="getValuation.market_value_post_renovation"
                                />
                            </template>
                        </div>
                    </article>
                </template>
                <template #postSectionsContent>
                    <div class="px-6 py-2">
                        <div class="image is-square">
                            <re-map
                                :building-id="getFeatures.building_id"
                                :parcel-ids="getFeatures.parcel_ids"
                                :level="getFeatures.level"
                                :region="getFeatures.region"
                                :center="{
                                    lat: getFeatures.f_lat,
                                    lng: getFeatures.f_lng,
                                }"
                                :map-scene2d="get_map_scene2d"
                                :read-only="true"
                                :focus-layer="'selected'"
                                class="has-ratio leaflet-transparent"
                                :size="'medium'"
                                :layers-control-visible="false"
                                :options="map_options"
                                :flood-visible="false"
                                :aerial-tile-visible="false"
                            ></re-map>
                        </div>
                    </div>
                </template>
            </report-page>
            <report-page :page="pages.building"></report-page>
            <report-page :page="pages.parcels">
                <template #postSectionsContent>
                    <div class="content mt-4">
                        <p class="has-text-weight-bold has-text-black is-size-6">
                            {{ $t('dvm_features.remarks') }}
                        </p>
                        <p class="is-size-7">{{ getValuation.remarks }}</p>
                    </div>
                </template>
            </report-page>
            <report-page :page="pages.surroundings"></report-page>
            <report-page :page="pages.interior"></report-page>
            <report-page
                v-for="page of roomPages"
                :key="page.name"
                :page="page"
            ></report-page>
            <report-page :page="pages.valuation">
                <template #postSectionsContent>
                    <div class="content">
                        <p class="is-size-7">{{ $t('valuation.explanation_1') }}</p>
                        <p class="has-text-weight-bold has-text-black is-size-7">
                            {{ $t('valuation.explanation_2') }}
                        </p>
                        <p v-if="!isPlot" class="is-size-7">
                            {{ $t('valuation.explanation_3') }}
                        </p>
                        <p v-else class="is-size-7">
                            {{ $t('valuation.construction_plot_explanation_3') }}
                        </p>
                        <p class="has-text-weight-bold has-text-black is-size-7">
                            {{ $t('valuation.explanation_4') }}
                        </p>
                        <p v-if="!isPlot" class="is-size-7">
                            {{ $t('valuation.explanation_5') }}
                        </p>
                        <p v-else class="is-size-7">
                            {{ $t('valuation.construction_plot_explanation_5') }}
                        </p>
                        <div v-if="!isPlot" class="chart1">
                            <cost-plot
                                :part="2"
                                :estimation_results_data="
                                    getAddress.estimationResultsData
                                "
                                :input_data="getFeatures"
                            />
                        </div>
                        <p v-if="isOVM" class="is-size-7 render-newlines">
                            {{ $t('valuation.onsite_explanation_6') }}
                        </p>
                        <p v-else-if="!isPlot" class="is-size-7 render-newlines">
                            {{ $t('valuation.explanation_6') }}
                        </p>
                        <p
                            v-if="has_reconstruction_value"
                            class="has-text-weight-bold has-text-black is-size-7"
                        >
                            {{ $t('disclaimer.reconstruction_value_title') }}
                        </p>
                        <p v-if="has_reconstruction_value" class="is-size-7">
                            {{
                                $t('disclaimer.reconstruction_value', {
                                    index_type: (
                                        getValuation.index_type ?? 'abex'
                                    ).toUpperCase(),
                                    index_value: getValuation.index_value ?? 847,
                                })
                            }}
                        </p>
                    </div>
                </template>
                <template #footer>
                    <report-footer :page-number="pages.valuation.index">
                        <p v-if="!isPlot" class="is-size-7">
                            (*) {{ $t('footnote.avm') }}: {{ avm_q50 }},
                            {{ $t('footnote.q25') }}: {{ avm_q25 }},
                            {{ $t('footnote.q75') }}:
                            {{ avm_q75 }}
                        </p>
                        <p v-if="packageType === 'renovation_light'" class="is-size-7">
                            (**) {{ $t('footnote.no_renovation_included') }}
                        </p>
                    </report-footer>
                </template>
            </report-page>
            <report-page :page="pages.reference_properties_location">
                <template #postSectionsContent>
                    <div class="content">
                        <p class="is-size-7">
                            {{
                                $t('reference_properties_location.explanation_1', {
                                    num_references: num_references,
                                    reference_radius: reference_radius,
                                })
                            }}
                        </p>
                        <p class="is-size-7">
                            {{ $t('reference_properties_location.explanation_2') }}
                        </p>
                    </div>
                    <div class="map">
                        <step-1 />
                    </div>
                </template>
            </report-page>
            <report-page :page="pages.reference_properties_characteristics">
                <template #postSectionsContent>
                    <div class="content">
                        <p>
                            {{ $t('reference_properties_characteristics.explanation_1') }}
                        </p>
                        <div class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <div class="icon-text is-flex is-align-items-center">
                                        <span class="icon has-text-secondary is-medium">
                                            <i class="mdi mdi-circle mdi-24px"></i>
                                        </span>
                                        <span>
                                            {{
                                                $t(
                                                    'reference_properties_characteristics.property_characteristics'
                                                )
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <span class="icon-text">
                                        <span class="icon has-text-primary">
                                            <i class="mdi mdi-circle"></i>
                                        </span>
                                        <span>
                                            {{
                                                $t(
                                                    'reference_properties_characteristics.properties_in_the_area'
                                                )
                                            }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="chart in feature_charts"
                        :key="chart.id"
                        class="chart-box"
                    >
                        <div class="has-text-weight-bold has-text-black is-size-6">
                            {{ $t(`features.${chart.feature}`) }}
                        </div>
                        <univariate-plots
                            class="chart"
                            :feature="chart.feature"
                            :estimation_results_data="getAddress.estimationResultsData"
                            :input_data="getFeatures"
                        />
                    </div>
                </template>
            </report-page>
            <report-page :page="pages.flood">
                <template #postSectionsContent>
                    <p>{{ $t('flood.explanation_1') }}</p>
                    <div class="map">
                        <re-map
                            :building-id="getFeatures.building_id"
                            :parcel-ids="getFeatures.parcel_ids"
                            :level="getFeatures.level"
                            :region="getFeatures.region"
                            :center="{
                                lat: getFeatures.f_lat,
                                lng: getFeatures.f_lng,
                            }"
                            :map-scene2d="get_map_scene2d"
                            :read-only="true"
                            :focus-layer="'flood'"
                            :layers-control-visible="false"
                            :options="map_options"
                            :aerial-tile-visible="false"
                            :cadastral-tile-visible="false"
                        ></re-map>
                    </div>
                </template>
            </report-page>
        </div>
    </div>
</template>

<script>
// TODO: refactor structure of this component according to https://github.com/rockestate/rockestate_vue/pull/781#discussion_r1417104856
//import { Previewer } from 'pagedjs'
import utils from '@/shared/plugins/utils'

//let paged = new Previewer()
import CostPlot from '../Avm/FormSteps/CostPlot.vue'
import ReMap from '@/components/common/Map.vue'
import ReportFooter from './Footer.vue'
import ReportPage from './ReportPage.vue'
import ReportMarketValue from './ReportMarketValue.vue'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'
import RotatingMarker from '@/components/common/RotatingMarker.vue'
import Step1 from '../Avm/FormSteps/Step1.vue'
import UnivariatePlots from '../Avm/FormSteps/UnivariatePlots.vue'
// import UrlText from './UrlText.vue'

import { icon } from 'leaflet'

import { mapMutations, mapGetters } from 'vuex'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})
export default {
    name: 'Report',
    components: {
        CostPlot,
        ReMap,
        ReportFooter,
        ReportPage,
        ReportMarketValue,
        Renderer,
        RotatingMarker,
        Step1,
        //Streetview,
        UnivariatePlots,
        // UrlText,
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Report',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    data() {
        return {
            icon: icon({
                iconUrl: `${this.$config.FRONTEND_URL}sector.svg`,
                iconSize: [40, 20],
                iconAnchor: [20, 20],
            }),
            showNull: false,
            accreditations: [],
        }
    },
    computed: {
        ...mapGetters([
            'getFullValuationStore',
            'getDVMFeatures',
            'getOVMFeatures',
            'getValuation',
        ]),
        ...mapGetters('valuationStore', [
            'valuer',
            'borrower',
            'getValuationType',
            'purpose',
        ]),
        ...mapGetters('valuationStore/Address', [
            'getAddress',
            'getFeatures',
            'getView',
            'get_map_scene2d',
            'get_map_scene3d',
            'getAvmTransaction',
            'getAvmTransactionEstimations',
        ]),
        ...mapGetters('auth', ['hasRole']),
        pages() {
            // TODO: consider moving this big piece of logic in its own component / composition API thingy
            const page_array = []
            page_array.push({
                name: 'general',
                sections: [{ name: 'general_info' }, { name: 'parcels_and_buildings' }],
            })
            if (!this.isPlot) {
                page_array.push({
                    name: 'building',
                    sections: [
                        { name: 'building', columns: 2 },
                        { name: 'building_structure', columns: 2 },
                        { name: 'building_purpose_classification', columns: 2 },
                    ],
                })
            }
            page_array.push({
                name: 'parcels',
                sections: [
                    ...(this.isPlot ? [{ name: 'building', columns: 1 }] : []),
                    { name: 'secondary_buildings2', columns: 2 },
                    { name: 'parcels', columns: 2 },
                ],
            })
            page_array.push({
                name: 'surroundings',
                sections: [
                    { name: 'surroundings', columns: 2 },
                    { name: 'mobility', columns: 2 },
                ],
            })
            if (this.getValuationType === 'ovm') {
                page_array.push({
                    name: 'interior',
                    sections: [
                        { name: 'interior', columns: 1 },
                        { name: 'common_parts', columns: 1 },
                    ],
                })
                const max_room_number_tracker = {}
                for (const floor of this.getOVMFeatures.floors) {
                    for (const [roomIndex, room] of floor.rooms.entries()) {
                        max_room_number_tracker[room.type] =
                            (max_room_number_tracker[room.type] ?? 0) + 1
                    }
                }
                const room_number_tracker = {}
                for (const floor of this.getOVMFeatures.floors) {
                    for (const [roomIndex, room] of floor.rooms.entries()) {
                        room_number_tracker[room.type] =
                            (room_number_tracker[room.type] ?? 0) + 1
                        const floor_name = `${this.$t('common.floor')} ${floor.level}`
                        let room_name = `${
                            this.$te('value.' + room.type)
                                ? this.$t('value.' + room.type)
                                : room.type
                        } ${
                            max_room_number_tracker[room.type] > 1
                                ? room_number_tracker[room.type]
                                : ''
                        }`
                        page_array.push({
                            name: `floor_${floor.level}_room_${roomIndex}`,
                            type: 'room',
                            sections: [
                                {
                                    name: 'room',
                                    data: {
                                        floor,
                                        room,
                                        name: `${floor_name} - ${room_name}`,
                                    },
                                },
                            ],
                        })
                    }
                }
            }
            page_array.push({
                name: 'valuation',
                sections: [{ name: 'valuations', orientation: 'horizontal' }],
            })
            if (!this.isPlot && this.hasAVM) {
                page_array.push({
                    name: 'reference_properties_location',
                    sections: [
                        { name: 'reference_properties_location', filterNull: false },
                    ],
                })
                page_array.push({
                    name: 'reference_properties_characteristics',
                    sections: [
                        {
                            name: 'reference_properties_characteristics',
                            filterNull: false,
                        },
                    ],
                })
            }
            page_array.push({
                name: 'flood',
                sections: [{ name: 'flood_simple' }],
            })
            const p = {}
            // fill defaults section params
            page_array.forEach((page, pageIndex) => {
                page.sections.forEach((section) => {
                    section.filterNull = section.filterNull ?? !this.showNull
                    section.columns = section.columns ?? 1
                    section.filterBuildingType =
                        section.filterBuildingType ?? this.getFeatures.f_building_type
                    section.orientation = section.orientation ?? 'vertical'
                })
                page.index = pageIndex + 1
                p[page.name] = page
            })

            return p
        },
        roomPages() {
            return Object.values(this.pages).filter((page) => page.type === 'room')
        },
        isPlot() {
            return ['construction_plot'].includes(this.getFeatures.f_building_type)
        },
        isOVM() {
            return this.getValuationType === 'ovm'
        },
        performedFor() {
            if (this.getValuationType === 'avm') {
                return '-'
            } else if (this.getValuationType === 'ovm') {
                return this.displayUser(this.borrower)
            } else {
                return this.bank_name
            }
        },
        feature_charts() {
            let features = []
            if (this.getFeatures.f_building_type === 'house') {
                features = [
                    'f_parcel_area',
                    'f_living_area',
                    'f_construction_year',
                    'f_epc',
                ]
            } else {
                features = ['f_living_area', 'f_construction_year', 'f_epc']
            }
            let charts = []
            for (let i = 0; i < features.length; i++) {
                if (this.getFeatures[features[i]] !== null) {
                    charts.push({
                        id: i + 1,
                        feature: features[i],
                        detailed: features[i] == 'f_living_area',
                    })
                }
            }
            return charts
        },
        packageType() {
            return this.getFeatures.renovation_cost >= 10000
                ? 'renovation_light'
                : 'classic'
        },
        bank_name() {
            return this.$config.LOCALE_OVERWRITE
                ? this.$config.LOCALE_OVERWRITE.toUpperCase()
                : 'XXXX'
        },
        formatted_date() {
            if (isNaN(this.getValuation.date)) return 'N/A'
            return date_formatter.format(this.getValuation.date)
        },
        map_options() {
            let static_map = this.$route.query.static === 'true'
            return {
                zoomControl: !static_map,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: !static_map,
                boxZoom: !static_map,
                dragging: !static_map,
            }
        },
        ovm_valuer() {
            return this.valuer ?? {}
        },
        dvm_valuer_name() {
            let valuer = this.getValuation.valuer
            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            }
            if (typeof valuer === 'string') {
                if (valuer.includes('@')) {
                    // Extract name from email
                    return valuer
                        .split('@')[0]
                        .split('.')
                        .map(capitalizeFirstLetter)
                        .join(' ')
                } else {
                    return valuer
                }
            } else {
                return valuer
            }
        },
        hasAVM() {
            return this.getAvmTransaction.status === 200
        },
        avm_q50() {
            if (this.isPlot) return 0
            return this.getAvmTransactionEstimations.asking_price_q50.toLocaleString(
                'fr-BE',
                {
                    style: 'currency',
                    currency: 'EUR',
                    maximumSignificantDigits: 3,
                }
            )
        },
        avm_q25() {
            if (this.isPlot) return 0
            // To get the q25 estimation, divide q50 by the q75 relative error
            let q75_error =
                this.getFeatures.f_building_type === 'house' ? 1.21248 : 1.232495
            return (
                this.getAvmTransactionEstimations.asking_price_q50 / q75_error
            ).toLocaleString('fr-BE', {
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3,
            })
        },
        avm_q75() {
            if (this.isPlot) return 0
            // To get the q75 estimation, divide q50 by the q25 relative error
            let q25_error =
                this.getFeatures.f_building_type === 'house' ? 0.92871 : 0.956576
            return (
                this.getAvmTransactionEstimations.asking_price_q50 / q25_error
            ).toLocaleString('fr-BE', {
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3,
            })
        },
        num_references() {
            if (this.isPlot) return 0
            return this.getFullValuationStore.Address.avm_transaction.body
                .reference_listings.length
        },
        reference_radius() {
            if (this.isPlot) return 0
            return Math.ceil(
                Math.max.apply(
                    null,
                    this.getFullValuationStore.Address.avm_transaction.body.reference_listings.map(
                        (l) => l.distance / 1000
                    )
                )
            )
        },
        has_reconstruction_value() {
            return (
                this.$config.RECONSTRUCTION_VALUE_ENABLED &&
                this.getValuation.reconstruction_value
            )
        },
    },
    watch: {
        valuer() {
            this.loadAccreditations()
        },
    },
    mounted() {
        this.loadAccreditations()
    },
    methods: {
        ...mapMutations('valuationStore/Address', ['SET_MAP_SCENE3D']),
        displayUser(user) {
            user = user ?? {}
            let d = ''
            if (user.first_name) {
                d += user.first_name
                if (user.last_name) {
                    d += ' ' + user.last_name
                }
            } else {
                d = user.email ?? '-'
            }
            return d
        },
        loadAccreditations() {
            const url = utils.urlJoin(this.$config.AUTH_API_URL, [
                'accreditations',
                'user',
                this.valuer.id,
            ])

            this.$axios
                .get(url)
                .then((response) => {
                    this.accreditations = response.data.filter(
                        (a) => a.accreditation_number
                    )
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.report-container {
    overflow: scroll;
    @media print {
        overflow: visible;
    }
}
</style>
<style lang="scss">
#renderer-3d {
    height: 150px;
    width: 100%;
}
#toc_val_report {
    top: 5.5rem;
    right: 10px;
    width: 220px;
    position: fixed;
    z-index: 500; // .leaflet-pane has a z-index of 400
}
.page {
    z-index: 1;
}
@page {
    size: A4;
}
body {
    background: white;
}
.cover-page {
    background: url('../../../../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: contain;
    position: relative;
}
.page {
    width: 794px;
    height: 1122px;
    padding: 0cm;
    margin: 1cm auto;
    border: 1px #d3d3d3 solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
@media print {
    body.has-navbar-fixed-top {
        padding-top: 0;
        margin-top: 0;
    }
    #navbar,
    #toc_val_report {
        display: none;
    }
    .page {
        width: 794px;
        height: 1122px;
        padding: 0;
        margin: 0;
        border: 0;
        box-shadow: none;
        page-break-after: always;
        overflow: hidden;
    }
    .page-faq {
        display: none;
    }
}
.report-footer {
    margin-top: auto;
    padding-top: auto;
    background-color: transparent;
}
.mt-10 {
    margin-top: 5rem;
}
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}
.chart1 {
    max-width: 940px;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    padding-top: 45px;
    &.chart2 {
        max-width: 820px;
        transform: scale(1.3) translateY(-90px);
        margin-top: 50px;
        margin-bottom: 0px;
    }
}
.map {
    width: 100%;
    height: 600px;
}
.leaflet-transparent {
    background-color: rgba(255, 0, 0, 0);
}
.gmnoprint,
.gm-style-cc {
    display: none;
}
.render-newlines {
    white-space: pre-line;
}
.cover_disclaimer {
    position: absolute;
    top: 50%;
    left: 50%;
    justify-content: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    white-space: nowrap;

    font-size: 4rem;
    font-weight: 700;
    color: rgba(255, 0, 0, 0.5);
}
</style>
